/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react'
import { motion } from 'framer-motion'

import useDevice from '~/hooks/client/mediaQueries'

import Button from '~/components/UI/Button/Button'

export const TitleAnim = ({ data }: any) => {
  const [width, setWidth] = useState<number>(1000)
  const [delay, setDelay] = useState<number>(1.5)
  const containerRef = useRef<HTMLDivElement>(null)
  const device = useDevice()

  const updateWidth = () => {
    if (containerRef.current) {
      setWidth(containerRef.current?.clientWidth)
    }
  }

  useEffect(() => {
    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelay(0)
    }, 1500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // Buttons and tags animation
  const slideIn = {
    hidden: {
      opacity: 0,
      x: '-10px'
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'just',
        duration: 0.8,
        delay: 1.4
      }
    }
  }

  // Heading animation
  const borderWidth = {
    small: {
      borderLeftWidth: '5px',
      borderRightWidth: '5px'
    },
    big: {
      borderLeftWidth: '10px',
      borderRightWidth: '10px',
      transition: { type: 'just', duration: 0.6, delay: 1.5 }
    }
  }

  const containerWidth = {
    width: width > 549 ? width : 'auto',
    transition: {
      type: device.isSmall ? 'spring' : 'just',
      damping: 6,
      stiffness: 20,
      duration: 0.6,
      delay: delay
    }
  }

  return (
    <>
      {data?.tag && (
        <motion.p
          className='tag text-white'
          variants={slideIn}
          initial='hidden'
          animate='show'
        >
          {data?.tag}
        </motion.p>
      )}
      <motion.div
        className='mb-lg border-char lg:mx-auto'
        variants={borderWidth}
        initial='small'
        animate='big'
      >
        <motion.div
          className='flex max-w-full items-center justify-center overflow-hidden'
          initial={{ width: '0' }}
          animate={containerWidth}
        >
          <div
            className='text-balanced px-[50px] py-[15px] text-center sm:whitespace-nowrap'
            ref={containerRef}
          >
            <h1 className='mb-0'>
              {data?.title}
              {data?.subTitle && <em className='block'>{data?.subTitle}</em>}
            </h1>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        className='flex flex-col gap-6 sm:flex-row sm:flex-wrap sm:justify-center sm:gap-md'
        variants={slideIn}
        initial='hidden'
        animate='show'
      >
        {data?.uiPrimaryButtons.map((button: buttonType, index: Index) => (
          <Button
            key={index}
            btn={button}
            colour={button?.colours.toLowerCase()}
          />
        ))}
      </motion.div>

      {data?.subtext && (
        <motion.div
          className='mt-8 sm:mt-14'
          variants={slideIn}
          initial='hidden'
          animate='show'
        >
          <p>{data?.subtext}</p>
        </motion.div>
      )}
    </>
  )
}
